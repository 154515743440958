var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.api ? _vm.api.isLoading : false,"search":_vm.search,"custom-filter":_vm.customFilter,"mobile-breakpoint":_vm.bread == 'read' ? 9999 : 600,"hide-default-footer":true,"disable-sort":true,"disable-filtering":_vm.isSearchable ? _vm.isSearchable : false,"disable-pagination":true,"single-expand":true,"expanded":_vm.expanded,"show-expand":_vm.expandable ? _vm.expandable : false,"no-data-text":_vm.$t('api.response.no_data'),"no-results-text":_vm.$t('api.response.no_data')},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(index + 1))])]}},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.replaceLogoUrl)?_c('v-img',{staticClass:"mt-3 ml-auto",attrs:{"max-height":"150","max-width":"150","src":_vm.replaceLogoUrl}}):_vm._e()],1),_c('div',[(!_vm.replaceLogoUrl)?_c('v-img',{staticClass:"mt-3 ml-auto",attrs:{"max-height":"150","max-width":"150","src":_vm.logoUrl ? _vm.logoUrl : item.imageUrl}}):_vm._e()],1),((item.imageUrl || _vm.logoUrl ) && _vm.schoolLogo)?_c('div',[_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.replaceLogo()}}},[_vm._v("Replace Image")])],1):_c('div',{staticClass:"text-right"},[_c('FormLogo',{staticClass:"pt-3",attrs:{"model":_vm.model,"callbackSuccess":_vm.callbackSuccess,"callbackPreview":_vm.callbackPreview}})],1)]}},{key:"item.drawing",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-1",attrs:{"contain":"","max-width":"80","max-height":"80","src":item.imageUrl}})]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [(item.images)?_c('div',{staticClass:"d-flex"},[_vm._l((Object.entries(JSON.parse(item.images))),function(img){return [_c('v-tooltip',{key:img[1].url,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"contain":"","max-width":"50","max-height":"30","src":img[1].url}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_ucfirst(img[0])))])])]})],2):_vm._e()]}},{key:"item.schoolYear",fn:function(ref){
var item = ref.item;
return [(item.schoolYear)?_c('div',[_vm._v(" "+_vm._s(_vm.$_.find(_vm.datasetSchoolYears, { id: item.schoolYear }).name)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.Syllabus",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'PageMainDiscover' }}},[_vm._v(_vm._s(item.syllabusShortname))])]}},_vm._l((_vm.models),function(model){return {key:("item." + model),fn:function(ref){
var item = ref.item;
return [_c('DataTableColumnModel',{key:model,attrs:{"objectKey":model,"item":item,"role":_vm.role}})]}}}),{key:"item.eventPassword",fn:function(ref){
var item = ref.item;
return [_c('Password',{attrs:{"callbackSuccess":_vm.callbackSuccess,"value":item.password,"eventId":item.id},on:{"refetch":_vm.refetchInfo}})]}},{key:"item.achievementProgressTimestampCompleted",fn:function(ref){
var item = ref.item;
return [(item.timestampCompleted)?_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampCompleted).fromNow()))]):_c('span',[_vm._v("-")])]}},{key:"item.achievementProgressTimestampClaimed",fn:function(ref){
var item = ref.item;
return [(item.timestampClaimed)?_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampClaimed).fromNow()))]):_c('span',[_vm._v("-")])]}},{key:"item.month",fn:function(ref){
var item = ref.item;
return [(item.month)?_c('span',[_vm._v(_vm._s(_vm.$moment().month(item.month - 1).format('MMMM')))]):_c('span',[_vm._v("-")])]}},{key:"item.achievementProgressProgress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"height":"25","value":(item.current / item.target) * 100}},[_vm._v(_vm._s((item.current / item.target) * 100)+"%")])]}},{key:"item.schoolMessageInvitation",fn:function(ref){
var item = ref.item;
return [_c('DataTableColumnMessage',{attrs:{"object":"schoolMessageInvitation","title":_vm.$t("action.send_invitation"),"item":item}})]}},{key:"item.classroomMessageInvitation",fn:function(ref){
var item = ref.item;
return [_c('DataTableColumnMessage',{attrs:{"object":"classroomMessageInvitation","title":_vm.$t("action.send_invitation"),"item":item}})]}},{key:"item.eventMessageInvitation",fn:function(ref){
var item = ref.item;
return [_c('DataTableColumnMessage',{attrs:{"object":"eventMessageInvitation","title":_vm.$t("action.send_invitation"),"item":item}})]}},{key:"item.festivalMessageInvitation",fn:function(ref){
var item = ref.item;
return [_c('FestivalMessage',{attrs:{"item":item}})]}},{key:"item.eventMessageNotice",fn:function(ref){
var item = ref.item;
return [_c('Notice',{attrs:{"item":item}})]}},{key:"item.festivalMessageNotice",fn:function(ref){
var item = ref.item;
return [_c('FestivalNotice',{attrs:{"item":item}})]}},{key:"item.eventPass",fn:function(ref){
var item = ref.item;
return [_c('Pass',{attrs:{"item":item}})]}},{key:"item.eventMessageScoreboard",fn:function(ref){
var item = ref.item;
return [(!_vm.reload)?_c('DataTableColumnMessage',{attrs:{"object":"eventMessageScoreboard","title":_vm.$t("view.PageBackendEvent.send_scoreboard"),"item":item}}):_vm._e()]}},{key:"item.eventStatus",fn:function(ref){
var item = ref.item;
return [(
          _vm.$moment() >= _vm.$moment(item.timestampStart) &&
          _vm.$moment() < _vm.$moment(item.timestampEnd)
        )?_c('v-chip',{attrs:{"small":"","dark":"","color":"green"}},[_vm._v(_vm._s(_vm.$t("model.value.status.ongoing")))]):(_vm.$moment(item.timestampEnd) < _vm.$moment())?_c('v-chip',{attrs:{"small":"","dark":"","color":"gray"}},[_vm._v(_vm._s(_vm.$t("model.value.status.past")))]):_c('v-chip',{attrs:{"small":"","dark":"","color":"orange"}},[_vm._v(_vm._s(_vm.$t("model.value.status.coming")))])]}},{key:"item.jobStatus",fn:function(ref){
        var item = ref.item;
return [(item.status == 'success')?_c('v-chip',{attrs:{"small":"","dark":"","color":"green"}},[_vm._v("Success")]):(item.status == 'stopped')?_c('v-chip',{attrs:{"small":"","dark":"","color":"red"}},[_vm._v("Stopped")]):_c('v-chip',{attrs:{"small":"","dark":"","color":"orange"}},[_vm._v("Running")])]}},{key:"item.isDeleted",fn:function(ref){
        var item = ref.item;
return [(item.timestampDeleted)?_c('v-chip',{attrs:{"small":"","dark":"","color":"red"}},[_vm._v("Yes")]):_c('v-chip',{attrs:{"small":"","dark":"","color":"green"}},[_vm._v("No")])]}},{key:"item.applicationStatus",fn:function(ref){
        var item = ref.item;
return [(item.status == 'pending')?_c('v-chip',{attrs:{"small":"","dark":"","color":"orange"}},[_vm._v("Pending")]):(item.status == 'approved')?_c('v-chip',{attrs:{"small":"","dark":"","color":"green"}},[_vm._v("Approved")]):(item.status == 'canceled')?_c('v-chip',{attrs:{"small":"","dark":"","color":"gray"}},[_vm._v("Canceled")]):_c('v-chip',{attrs:{"small":"","dark":"","color":"red"}},[_vm._v("Rejected")])]}},{key:"item.submissionAccuracy",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.accuracy * 100)+"%")])]}},{key:"item.submissionStar",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.star)+"★")])]}},{key:"item.participantProgress",fn:function(ref){
        var item = ref.item;
return [_c('v-progress-linear',{attrs:{"height":"25","value":item.progress * 100}},[_vm._v(_vm._s(item.progress * 100)+"%")])]}},{key:"item.accountSelect",fn:function(ref){
        var item = ref.item;
return [_c('DataTableButtonAccountSelect',{attrs:{"item":item,"role":_vm.role}})]}},{key:"item.ClassroomName",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name:'PageModeratorClassroomRead',params:{id:item.Student.Classroom.id}}}},[_vm._v(" "+_vm._s(item.Student.Classroom.name)+" ")])]}},{key:"item.SyllabusName",fn:function(ref){
        var item = ref.item;
return [(item.Syllabus && item.Syllabus.translatableName)?_c('span',[_vm._v(_vm._s(JSON.parse(item.Syllabus.translatableName)[_vm.$_getLocale()]))]):_c('span',[_vm._v("-")])]}},{key:"item.PositionName",fn:function(ref){
        var item = ref.item;
return [(item.Position && item.Position.translatableName)?_c('span',[_vm._v(_vm._s(JSON.parse(item.Position.translatableName)[_vm.$_getLocale()]))]):_c('span',[_vm._v("-")])]}},{key:"item.playerMochiId",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{name:'PageModeratorPlayerRead',params:{id:item.playerId}}}},[_vm._v(" "+_vm._s(item.mochiId)+" ")])]}},{key:"item.gameUrl",fn:function(ref){
        var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":_vm.urlGame + 'home/?code='+ item.code}},[_vm._v(" "+_vm._s(_vm.$t("action.click_here"))+" ")])]}},{key:"item.icon",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('span',[_c('v-icon',{attrs:{"aria-hidden":"false"}},[_vm._v(" "+_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.icon))])]}},{key:"item.color",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"pa-3",class:item.color},[_vm._v(" "+_vm._s(item.color)+" ")])]}},{key:"item.logoUrl",fn:function(ref){
        var item = ref.item;
return [_c('div',[(_vm.replaceLogoUrl)?_c('v-img',{staticClass:"mt-3 ml-auto",attrs:{"max-height":"150","max-width":"150","src":_vm.replaceLogoUrl}}):_vm._e()],1),_c('div',[(!_vm.replaceLogoUrl)?_c('v-img',{staticClass:"mt-3 ml-auto",attrs:{"max-height":"150","max-width":"150","src":_vm.logoUrl ? _vm.logoUrl : item.logoUrl}}):_vm._e()],1),((item.logoUrl || _vm.logoUrl ) && _vm.schoolLogo)?_c('div',[_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.replaceLogo()}}},[_vm._v(_vm._s(_vm.$t('action.replace')))]),_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.downloadImage(item.logoUrl ? item.logoUrl:_vm.logoUrl, item.name)}}},[_vm._v("Download")])],1):_c('div',{staticClass:"text-right"},[_c('FormLogo',{staticClass:"pt-3",attrs:{"model":_vm.model,"callbackSuccess":_vm.callbackSuccess,"callbackPreview":_vm.callbackPreview}})],1)]}},{key:"item.prizeImage",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","max-height":"80","max-width":"80","src":item.imageUrl}})]}},{key:"item.itemImage",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{staticClass:"ma-2",attrs:{"contain":"","max-height":"40","max-width":"40","src":item.Item.images.icon.url}})]}},{key:"item.itemPrice",fn:function(ref){
        var item = ref.item;
return [(item.Item.Currency)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"ma-2"},[_vm._v(_vm._s(item.Item.price))]),_c('v-img',{attrs:{"contain":"","max-height":"20","max-width":"20","src":item.Item.Currency.images.icon.url}})],1):_c('div',[_vm._v(" - ")])]}},{key:"item.buildingImage",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","max-height":"80","max-width":"80","src":item.imageUrl}})]}},{key:"item.avatar",fn:function(ref){
        var item = ref.item;
return [(item.avatar)?_c('AAvatar',{attrs:{"avatar":item.avatar}}):_c('div',[_c('v-img',{attrs:{"max-height":40,"max-width":40,"src":require('@/assets/CS0040_default_F0.png')}})],1)]}},{key:"item.PIN",fn:function(ref){
        var item = ref.item;
return [(item.isEnabledPIN)?_c('div',[_vm._v(" "+_vm._s(item.PIN)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.url",fn:function(ref){
        var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":item.url}},[_vm._v(_vm._s(item.url))])]}},{key:"item.textEventRule",fn:function(ref){
        var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"item.translatableDescription",fn:function(ref){
        var item = ref.item;
return [(item.translatableDescription)?_c('span',[_vm._v(_vm._s(JSON.parse(item.translatableDescription)[_vm.$_getLocale()]))]):_c('span',[_vm._v("-")])]}},{key:"item.translatableName",fn:function(ref){
        var item = ref.item;
return [(item.translatableName)?_c('span',[_vm._v(_vm._s(JSON.parse(item.translatableName)[_vm.$_getLocale()]))]):_c('span',[_vm._v("-")])]}},{key:"item.prizeName",fn:function(ref){
        var item = ref.item;
return [(item.eventPrize)?_c('span',[(item.GamePrize.PrizeType.translatableName)?_c('span',[_vm._v(_vm._s(JSON.parse(item.GamePrize.PrizeType.translatableName)[_vm.$_getLocale()])+" ")]):_c('span',[_vm._v("-")])]):_c('span',[_vm._v(_vm._s(JSON.parse(item.PrizeType.translatableName)[_vm.$_getLocale()]))])]}},{key:"item.teacherName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.User.name)),(item.User.accountId == item.Institution.accountId)?_c('span',{staticClass:"pl-2"},[_c('v-chip',{attrs:{"color":"primary","outlined":"","small":""}},[_vm._v("Admin")])],1):_vm._e()]}},{key:"item.festivalPrizeName",fn:function(ref){
        var item = ref.item;
return [(item.prizeName.includes('{'))?_c('span',[_vm._v(_vm._s(JSON.parse(item.prizeName)[_vm.$_getLocale()])+" ")]):_c('span',[_vm._v(_vm._s(item.prizeName))])]}},{key:"item.festivalItemName",fn:function(ref){
        var item = ref.item;
return [(item.itemName.includes('{'))?_c('span',[_vm._v(_vm._s(JSON.parse(item.itemName)[_vm.$_getLocale()])+" ")]):_c('span',[_vm._v(_vm._s(item.itemName))])]}},{key:"item.enhancedPassword",fn:function(ref){
        var item = ref.item;
return [(item.isEnhancedPassword)?_c('span',[_vm._v(_vm._s(item.password)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.prizeRewards",fn:function(ref){
        var item = ref.item;
return [_vm._l((item.Rewards),function(reward,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(JSON.parse(reward.Item.translatableName)[_vm.$_getLocale()])+" x "+_vm._s(reward.quantity)+" ")])}),_c('br')]}},{key:"item.dateStart",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampStart).format("DD MMM YYYY")))])]}},{key:"item.dateEnd",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampEnd).format("DD MMM YYYY")))])]}},{key:"item.timestampStart",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampStart).format("DD MMM YYYY hh:mm A")))])]}},{key:"item.timestampEnd",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampEnd).format("DD MMM YYYY hh:mm A")))])]}},{key:"item.dateCreated",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampCreated).format("YYYY-MM-DD")))])]}},{key:"item.dateUpdated",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampUpdated).format("YYYY-MM-DD")))])]}},{key:"item.timestampCreated",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampCreated).format("YYYY-MM-DD HH:mm:ss")))])]}},{key:"item.timestampScheduled",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampScheduled).format("YYYY-MM-DD HH:mm:ss")))])]}},{key:"item.timestampInvited",fn:function(ref){
        var item = ref.item;
return [(item.InvitedAccount[0])?_c('span',[_vm._v(_vm._s(_vm.$moment(item.InvitedAccount[0].timestampInvited).format("YYYY-MM-DD HH:mm:ss")))]):_c('span',[_vm._v("-")])]}},{key:"item.timestampUpdated",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampUpdated).format("YYYY-MM-DD HH:mm:ss")))])]}},{key:"item.nameWithUrl",fn:function(ref){
        var item = ref.item;
return [(item.websiteUrl)?_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.websiteUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name))]):_c('span',[_vm._v(" "+_vm._s(item.name))])]}},{key:"item.websiteUrl",fn:function(ref){
        var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.websiteUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.websiteUrl))])]}},{key:"item.joinedStatus",fn:function(ref){
        var item = ref.item;
return [((item.User.Account.InvitedAccount[0] && item.User.Account.InvitedAccount[0].timestampPasswordChanged))?_c('span',[_vm._v(_vm._s(_vm.$moment(item.User.Account.InvitedAccount[0].timestampPasswordChanged).format("DD MMM YYYY hh:mm A")))]):(!item.User.Account.InvitedAccount[0])?_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampCreated).format("YYYY-MM-DD HH:mm:ss"))+" ")]):_c('span',[_c('v-chip',{attrs:{"x-small":""}},[_vm._v("Pending Invite")])],1)]}},{key:"item.joinedStatusRead",fn:function(ref){
        var item = ref.item;
return [((item.User.Account.InvitedAccount[0] && item.User.Account.InvitedAccount[0].timestampPasswordChanged))?_c('span',[_vm._v(_vm._s(_vm.$moment(item.User.Account.InvitedAccount[0].timestampPasswordChanged).format("DD MMM YYYY hh:mm A")))]):(!item.User.Account.InvitedAccount[0])?_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampCreated).format("YYYY-MM-DD HH:mm:ss"))+" ")]):(item.User && item.User.Account && item.User.Account.InvitedAccount[0] && !item.User.Account.InvitedAccount[0].timestampPasswordChanged)?_c('span',[_c('DataTableColumnInviteMenu',{attrs:{"password":item.User.Account.InvitedAccount[0].password,"mobile":item.User.Account.mobile}})],1):_vm._e()]}},{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [_c('DataTableColumnBoolean',{attrs:{"value":item.isActive,"textTrue":_vm.$t("model.value.status.active"),"textFalse":_vm.$t("model.value.status.inactive")}})]}},{key:"item.isTransferred",fn:function(ref){
        var item = ref.item;
return [_c('DataTableColumnBoolean',{attrs:{"value":item.isTransferred}})]}},{key:"item.isEnable",fn:function(ref){
        var item = ref.item;
return [_c('DataTableColumnBoolean',{attrs:{"value":item.enableWalkin,"textTrue":_vm.$t("model.value.status.enable"),"textFalse":_vm.$t("model.value.status.disable")}})]}},{key:"item.isExpress",fn:function(ref){
        var item = ref.item;
return [_c('DataTableColumnBoolean',{attrs:{"value":item.isExpress,"textTrue":_vm.$t("model.value.status.enable"),"textFalse":_vm.$t("model.value.status.disable")}})]}},{key:"item.isPublished",fn:function(ref){
        var item = ref.item;
return [_c('DataTableColumnBoolean',{attrs:{"value":item.isPublished}})]}},{key:"item.isSentGift",fn:function(ref){
        var item = ref.item;
return [_c('DataTableColumnBoolean',{attrs:{"value":item.timestampGiftSent ? true : false}})]}},{key:"item.hasLeagueRewardSent",fn:function(ref){
        var item = ref.item;
return [_c('DataTableColumnBoolean',{attrs:{"value":item.hasLeagueRewardSent ? true : false}})]}},{key:"item.actionView",fn:function(ref){
        var item = ref.item;
return [_c('v-col',{staticClass:"text-right"},[(_vm.modelParent && !_vm.modelGrandParent)?_c('router-link',{staticClass:"text-decoration-none font-weight-medium",attrs:{"to":_vm.$_getRouteChildRead(
            _vm.role,
            _vm.model.key,
            item.id,
            item,
            _vm.modelParent.key,
            _vm.parent.id,
            _vm.parent
          )}},[_vm._v(_vm._s(_vm.$t("action.view")))]):(_vm.modelGrandParent)?_c('router-link',{staticClass:"text-decoration-none font-weight-medium",attrs:{"to":_vm.$_getRouteGrandChildRead(
            _vm.role,
            _vm.model.key,
            item.id,
            item,
            _vm.modelParent.key,
            _vm.parent.id,
            _vm.parent,
            _vm.modelGrandParent.key,
            _vm.grandParent.id,
            _vm.grandParent
          )}},[_vm._v(_vm._s(_vm.$t("action.view")))]):_c('router-link',{staticClass:"text-decoration-none font-weight-medium",attrs:{"to":_vm.$_getRouteRead(_vm.role, _vm.model.key, item.id, item)}},[_vm._v(_vm._s(_vm.$t("action.view")))])],1)]}},{key:"item.scoreboard",fn:function(ref){
          var item = ref.item;
return [_c('v-col',{staticClass:"text-right"},[_c('router-link',{staticClass:"text-decoration-none font-weight-medium",attrs:{"to":{ name: 'PageMainEventScoreboard', params: { eventCode: item.code } },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("action.scoreboard")))])],1)]}},{key:"item.institutionScoreboard",fn:function(ref){
          var item = ref.item;
return [_c('v-col',{staticClass:"text-right"},[_c('router-link',{staticClass:"text-decoration-none font-weight-medium",attrs:{"to":{ name: 'PageMainEventScoreboard', params: { eventCode: item.code }, query:{c: _vm.parent.code} },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("action.scoreboard")))])],1)]}},{key:"item.actionTrashed",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.$refs.dialogDestroy.open(item)}}},[_vm._v("DESTROY")]),_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.$refs.dialogRestore.open(item)}}},[_vm._v("RESTORE")])]}},{key:"item.delete",fn:function(ref){
          var item = ref.item;
return [_c('v-col',{staticClass:"text-right"},[_c('span',{staticClass:"red--text remove font-weight-medium",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.dialogSchoolYearDelete.open(item)}}},[_vm._v(_vm._s(_vm.$t("action.remove")))])])]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{staticClass:"pa-3",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.data)+" ")])]}}],null,true)}),_c('DataTableDialogDestroy',{ref:"dialogDestroy",attrs:{"role":_vm.role,"model":_vm.model}}),_c('DataTableDialogDelete',{ref:"dialogDelete",attrs:{"role":_vm.role,"model":_vm.model},on:{"refetch":_vm.refetchClassroom}}),_c('DataTableDialogDeleteSchoolYear',{ref:"dialogSchoolYearDelete",attrs:{"role":_vm.role,"model":_vm.model},on:{"refetch":_vm.refetchSchoolYear}}),_c('DataTableDialogRestore',{ref:"dialogRestore",attrs:{"role":_vm.role,"model":_vm.model}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }